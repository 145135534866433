export class cNavEcommaz {
    constructor() {

        // Callback before collapse navigation opens
        $('#navbarCollapse').on('show.bs.collapse', function (e) {
            var numWrapHeight = $(window).outerHeight() - $('#elFixedNavbar').outerHeight();
            $('#wrapCollapse').css('height',numWrapHeight);
            $('#btnHamburger').addClass('is-active');
        });
        // Callback before collapse navigation closes
        $('#navbarCollapse').on('hide.bs.collapse', function () {
            $('#btnHamburger').removeClass('is-active');
        });
        // Callback once the navigation is fully opened (after animation)
        $('#navbarCollapse').on('shown.bs.collapse', function () {
            //$('body').addClass('modal-open');
            $('body').addClass('stop-body-scroll');
        });
        // Callback once the navigation is fully closed (after animation)
        $('#navbarCollapse').on('hidden.bs.collapse', function () {
            $('#wrapCollapse').css('height', '');
            //$('body').removeClass('modal-open');
            $('body').removeClass('stop-body-scroll');
        });
    }
    /**
     * Compensate the fixed navbar height, (adding) this to the current body padding
     */
    compensateFixedNavbarHeight() {
        //let currentBodyPaddingTop = parseInt($('body').css('paddingTop'), 10);
        let heightFixedNavbar = $('#elFixedNavbar').outerHeight();
        $('body').css({
            'padding-top': /*currentBodyPaddingTop + */heightFixedNavbar
        });

    }

    resetOnResize() {
        $('#btnHamburger').removeClass('is-active').attr('aria-expanded', 'false');
        $('#navbarCollapse').removeClass('show');
        $('#wrapCollapse').css('height', '');
        $('body').removeClass('stop-body-scroll');
    }
}
