export class topNavAutoHide {

    constructor(el, offset, classname = 'is-hidden') {
        this.el = el;
        this.offset = offset;
        this.classname = classname;
        this.scrollLastTop = 0;
    }

    /**
     * Toggles the classname based of position and scroll behavior
     */
    toggleNavigation() {
        let st = $(window).scrollTop();
        if (st > 10) {
            $('.c-nav-ecommaz').removeClass('before-scroll');
        } else {
            $('.c-nav-ecommaz').addClass('before-scroll');
        }

        if (st >= this.scrollLastTop && st > this.offset)
            this.el.addClass(this.classname);
        else
            this.el.removeClass(this.classname);
        this.scrollLastTop = st;
    }
}
