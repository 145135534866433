// Bootstrap imports
// import 'bootstrap/js/dist/alert';
// import 'bootstrap/js/dist/button';
// import 'bootstrap/js/dist/carousel';
import 'bootstrap/js/dist/collapse';
import 'bootstrap/js/dist/dropdown';
// import 'bootstrap/js/dist/modal';
// import 'bootstrap/js/dist/popover';
// import 'bootstrap/js/dist/scrollspy';
// import 'bootstrap/js/dist/tab';
// import 'bootstrap/js/dist/toast';
// import 'bootstrap/js/dist/tooltip';

import { aysAnimateBody } from '@atyoursite/animation';

// module imports
import { throttle } from 'underscore';
import { cNavEcommaz } from './components/navbars';
import { topNavAutoHide } from './utils/navbars';

// Navigation
const navEcommaz = new cNavEcommaz();
navEcommaz.compensateFixedNavbarHeight();
window.addEventListener("resize", throttle(function () { navEcommaz.resetOnResize(); }, 500));

// Hide and show navigation based on scrolling position and behavior
const topNavHide = new topNavAutoHide($('#elFixedNavbar'), 800);
window.addEventListener("scroll", throttle(function () { topNavHide.toggleNavigation(); }, 300));

$('#slickTestimonials').slick({
    dots: true,
    infinite: true,
    speed: 300,
    slidesToShow: 1,
    adaptiveHeight: true
});

if ($('#videoYT').length) {
    const player = new Plyr('#videoYT', {});
    player.source = {
        type: 'video',
        sources: [
            {
                src: 'W41TGYXnnSY',
                provider: 'youtube'
            }
        ]
    };
}


ScrollReveal().reveal('.scrollreveal', { delay: 500 });

//* FIX: JQuery validator email validations
$.validator.addMethod("email", (value, element) => /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/.test(value) ? true : false);
/**/

//* FIX: JQuery validator email validations
grecaptcha.ready(function () {
    if ($('#recaptcha').length > 0) {
        var updateGoogleCaptchaLanguage = function (selectedLanguage) {
            var iframeGoogleCaptcha = (document.querySelector('.grecaptcha-logo iframe'));
            iframeGoogleCaptcha.src = iframeGoogleCaptcha.src.replace(/hl=(.*?)&/, 'hl=' + selectedLanguage + '&');
        };

        grecaptcha.execute(reCaptchaPublicKey, { action: 'homepage' }).then(function (token) {
            document.getElementById('recaptcha').value = token;
        });

        updateGoogleCaptchaLanguage('@Culture.Substring(0, 2).ToLower()');

        $('.grecaptcha-badge').css({
            'visibility': 'visible'
        });
    }

});
/**/

function focusFirstError(offset, onlyFocus, callback) {
    var $arrInputError = $('input.error');
    var $arrTextareaError = $('textarea.error');
    if (!offset)
        offset = 0;
    if ($arrInputError.length || $arrTextareaError.length) {
        var numFirstErrorOffsetTop = void 0;
        var $objFirstInvalidField = void 0;
        if ($arrInputError.length) {
            $objFirstInvalidField = $arrInputError.first();
        }
        else if ($arrTextareaError.length) {
            $objFirstInvalidField = $arrTextareaError.first();
        }
        numFirstErrorOffsetTop = $objFirstInvalidField.offset().top - offset;
        if (!onlyFocus) {
            if (callback)
                aysAnimateBody(numFirstErrorOffsetTop, 800, function () {
                    callback();
                });
            else
                aysAnimateBody(numFirstErrorOffsetTop, 800);
        }
        $objFirstInvalidField.focus();
    }
    if (callback && onlyFocus)
        callback();
}

if ($('#frmContact').length) {

    let selectr = new Selectr('#ddPackage', {
        multiple: true,
        placeholder: 'Selecteer oplossing(en)',
    });
    selectr.on('selectr.change', function () {
        let arrPackage = selectr.getValue();
        /* BUG: Removing the first string in the array
        *- Only when multiple mode is true
        *- https://github.com/Mobius1/Selectr/issues/93
        */
        let theRemovedElement = arrPackage.shift();
        theRemovedElement = null;
        $('#contactSolution').val(arrPackage);
    });
    let selectrTerm = new Selectr('#ddTerm');
    let selectrBudget = new Selectr('#ddBudget');

    $('#cbQuotation').on('change', (e) => {
        if ($('#cbQuotation:checkbox:checked').length > 0)
            $('.js-quotation').slideDown();
        else
            $('.js-quotation').slideUp();
    });

    $("#btnSendContact").click(function (e) {

        e.preventDefault();
        $('.error').removeClass('error');
        $('#frmContact').validate({
            errorPlacement: function (error, element) { },
            invalidHandler: function () { }
        });

        if ($('#frmContact').valid()) {
            //* Rotating load svg on button
            var btndom = $('#btnSendContact');
            btndom.addClass("loading-start");
            btndom.attr("disabled", true);
            /**/
            $('#frmContact').submit();
        }
        else {
            focusFirstError($('nav.navbar').outerHeight(true) + 40);
        }
    });
}

if ($('#frmAnalyse').length) {

    //let strParameter: string | false = getUrlParameter('formule');

    let selectr = new Selectr('#ddPackage', {
        multiple: true,
        placeholder: 'Selecteer oplossing(en)'
    });
    selectr.on('selectr.change', function () {
        let arrPackage = selectr.getValue();
        /* BUG: Removing the first string in the array
        *- Only when multiple mode is true
        *- https://github.com/Mobius1/Selectr/issues/93
        */
        let theRemovedElement = arrPackage.shift();
        theRemovedElement = null;
        //console.log(arrPackage);
        $('#analyseSolution').val(arrPackage);
    });
    //if (strParameter !== false && strParameter !== undefined)
    //    selectr.setValue(strParameter);

    $("#btnSendAnalyse").click(function (e) {

        e.preventDefault();
        $('.error').removeClass('error');
        $('#frmAnalyse').validate({
            errorPlacement: function (error, element) { },
            invalidHandler: function () { }
        });

        if ($('#frmAnalyse').valid()) {
            $('#frmAnalyse').submit();
            //let frmData: any = $('#frmAnalyse').serializeFormJSON();
            //let arrPackage = selectr.getValue();
            ///* BUG: Removing the first string in the array
            //*- Only when multiple mode is true
            //*- https://github.com/Mobius1/Selectr/issues/93
            //*/
            //let theRemovedElement = arrPackage.shift();
            //theRemovedElement = null;
            //frmData.package = arrPackage;
            ////* Start post
            //$.ajax({
            //    type: "POST",
            //    url: getBaseUrl() + "home/sendanalyseform",
            //    data: frmData,
            //    success: function () {
            //        $('#frmAnalyse').fadeOut(500, function () {
            //            $("#pnlResult").fadeIn(500);
            //            aysAnimateBody($('#pnlResult').offset().top - $('nav.navbar').outerHeight(true) - $('h1.page-title').outerHeight(true), 800);
            //        });
            //    }
            //});
            /* End post */
            /* Start debug emulation
            $('#frmAnalyse').fadeOut(500, function () {
                $("#pnlResult").fadeIn(500);
                aysAnimateBody($('#pnlResult').offset().top - $('nav.navbar').outerHeight(true) - $('h1.page-title').outerHeight(true), 800);
                console.log(frmData);
            });
            /* End debug emulation */
        }
        else {
            focusFirstError(($('nav.navbar').outerHeight(true) + 40, null));
        }
    });

}

$('.js-close-overlay').on('click', function () {
    let elTarget = $(this).attr('data-target');
    navEcommaz.resetOnResize();
    aysAnimateBody($(elTarget).offset().top - 80, 800);
});

// If the url contains a #, scroll to the right position
if (location.hash) {
    aysAnimateBody($(location.hash).offset().top - 80, 800);
    //console.log(location.hash);
}
